<template>
    <div class="row container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box opensans-bold">


        <div class="col-6 p-0 mb-3">
            <div class="row p-0 bg-lightgray pl-2 pb-3 rounded-left">

                <div class="col-12 col-xl-6 p-0">
                    <ki-select label="Almacén de origen:" @change="checkOriginWarehouseProducts"
                        :options="warehouse_options"
                        :hidden_option="'Seleccionar..'"
                        v-model="origin_warehouse_id"
                        full>
                    </ki-select>

                    <span class="text-danger" v-if="error_selected_products">
                        {{ error_selected_products }}
                    </span>
                </div>


                <div class="col-12 col-xl-6 p-0 pl-xl-2">
                    <ki-input @input="searchOriginItems" label="Búscar producto:"
                        placeholder="Ej: playera"
                        v-model="origin_search_query"
                        type="text"
                        full>
                    </ki-input>
                </div>


            </div> 
        </div>

        <div class="col-6 p-0 mb-3">
            <div class="row bg-lightgray pr-2 pb-3 rounded-right">

                <div class="col-12 col-xl-6 p-0  pl-2">
                    <ki-select label="Almacén de destino:" @change="checkDestinationWarehouseProducts"
                        :options="warehouse_options"
                        :hidden_option="'Seleccionar..'"
                        v-model="destination_warehouse_id"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="error_warehouse_id">
                        {{ error_warehouse_id }}
                    </span>
                </div>


                <div class="col-12 col-xl-6 p-0 pl-2">
                    <ki-input @input="searchDestinationItems" label="Búscar producto:"
                        placeholder="Ej: short"
                        v-model="destination_search_query"
                        type="text"
                        full>
                    </ki-input>
                </div>

            </div>
            
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div v-if="!loading && fetch_error" class="text-center text-dark">
            <p>
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 col-xl-6 p-0" v-if="!loading && !fetch_error">
            <div class="row p-0">

                <div class="col-12 p-0 text-dark">
                    <p>
                        Productos en almacén de origen:
                    </p>
                </div>

                <div class="col-12 p-0" v-if="origin_warehouse_table_data.length > 0">
                    <ki-table :data="origin_warehouse_table_data"
                        :allow_headers="origin_warehouse_table_headers"
                        no_pagination color_body="white" color_headers="lightgray">
                        <template v-slot:seleccion="data">
                            <ki-checkbox @click="selectProducts(data.item)" 
                                v-model="data.item.checked"
                                fill/>
                        </template>
                    </ki-table>
                </div>

                <div class="text-dark text-center" v-if="origin_warehouse_table_data.length <= 0 && origin_warehouse_id && !origin_search_query">
                    <p>
                        El almacén esta vació
                    </p>
                </div>

                <div class="text-dark text-center" v-if="origin_warehouse_table_data.length <= 0 && origin_search_query">
                    <p>
                        Búsqueda realizada sin exito.
                    </p>
                </div>

            </div>
        </div>

        <div class="col-12 mt-2 mt-xl-0 col-xl-6 p-0 pl-xl-1 text-dark" v-if="!loading && !fetch_error">
            <div class="row p-0">
                <div class="col-12 p-0">
                    <p>
                        Productos en el almacén de destino:
                    </p>
                </div>

                <div class="col-12 p-0" v-if="destination_warehouse_table_data.length > 0">
                    <ki-table :data="destination_warehouse_table_data"
                        :allow_headers="destination_warehouse_table_headers"
                        color_body="white" color_headers="lightgray"
                        no_pagination>
                    </ki-table>
                </div>

                <div class="text-dark text-center" v-if="destination_warehouse_table_data.length <= 0 && destination_warehouse_id && !destination_search_query">
                    <p>
                        El almacén esta vació
                    </p>
                </div>

                <div class="text-dark text-center" v-if="destination_warehouse_table_data.length <= 0 && destination_search_query">
                    <p>
                        Búsqueda realizada sin exito.
                    </p>
                </div>


            </div>
        </div>

        <div class="col-12 d-flex justify-content-end pt-3">
            <button @click.stop="getTransferHistory"
                class="btn btn-white pills"
                style="width:160px;"
                type="button">
                <img :src="clock_icon" />
                Historial
            </button>
            <button @click.stop="checkWarehouseId"
                class="btn btn-primary pills text-white ml-2"
                type="button">
                Mover productos
            </button>
        </div>


        <!-----------Products Modal------------->
        <ki-modal v-if="transaction_modal">
            <div class="row bg-white position-relative" style="border-radius: 10px">
                <button @click.stop="cancel"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)">
                    X
                </button>

                <div class="col-12 text-dark">
                    <p class="p-2">
                        Enviar productos a almacén:
                    </p>
                </div>

                <div class="col-6 text-dark">
                    <label class="d-flex">
                        Almacén de Origen:
                        <ki-input class="ml-2"
                            :disabled="true"
                            v-model="selected_origin_warehouse"
                            full>
                        </ki-input>
                    </label>
                </div>

                <div class="col-6 text-dark">
                    <label class="d-flex">
                        Almacén de destino:
                        <ki-input class="ml-2"
                            :disabled="true"
                            v-model="selected_destination_warehouse"
                            full>
                        </ki-input>
                    </label>
                </div>

                <div class="col-12 text-dark position-relative">
                    <div class="row p-0">

                        <div class="col-12 p-0">
                            <p>
                                Productos seleccionados:
                            </p>
                        </div>

                        <div v-if="selected_products_data.length > 0"
                            class="col-12 mb-2 p-0 overflow-auto" 
                            style="max-height: 400px;">
                            <ki-table :data="selected_products_data"
                                :allow_headers="selected_products_headers"
                                no_pagination color_body="white" color_headers="white">

                                <template v-slot:seleccion="data">
                                    <button @click.stop="removeSelectedProduct(data)"
                                        class="btn btn-danger btn-rounded p-0 m-0 border-0"
                                        style="width: 25px; height: 25px;"
                                        type="button">
                                        X
                                    </button>
                                </template>

                                <template v-slot:Cantidad="data">
                                    <ki-input 
                                        placeholder="Ej: 10"
                                        v-model="data.item.value"
                                        type="number">
                                    </ki-input>
                                    <span class="text-danger" v-if="error[selected_products_data.indexOf(data.item)]">
                                        {{ error[selected_products_data.indexOf(data.item)]  }}
                                    </span>
                                </template>

                            </ki-table>
                        </div>

                        <div class="col-12 d-flex justify-content-end m-3">
                            <button @click.stop="cancel"
                                class="btn tbn-white pills mr-2"
                                type="button">
                                Cancelar
                            </button>
                            <button @click.stop="processTransaction"
                                class="btn btn-white pills"
                                type="button">
                                <img :src="move_icon" />
                                Mover
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </ki-modal>

        <!----------Historial modal------------->
        <ki-modal v-if="historial_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 15px">
                <button @click.stop="(historial_modal = false)"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.5)"
                    type="button">
                    X
                </button>

                <div class="col-12 py-3">
                    <p>
                        Historial de traspasos
                    </p>
                </div>

                <div class="col-12">
                    <ki-table :data="historial_table_data"
                        :allow_headers="historial_table_headers"
                        no_pagination color_headers="white" color_body="white">
                    </ki-table>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="(historial_modal = false)"
                        class="btn btn-primary pills text-white m-3"
                        type="button">
                        Cerrar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //Icons
    import icon_search from 'ASSETS/icons/icon_search.png'
    import move_icon from 'ASSETS/icons/icon_move_blue.png'
    import clock_icon from 'ASSETS/icons/historial.svg'

    //helpers
    import { mapGetters } from 'vuex'



    export default {
        name: 'ki-warehousetransfer',

        data() {
            return {
                icon_search,
                move_icon,
                clock_icon,
                transaction_modal: false,
                historial_modal: false,
                loading: false,
                fetch_error: false,
                warehouse_options: [],
                origin_warehouse_id: null,
                destination_warehouse_id: null,
                origin_search_query: null,
                destination_search_query: null,
                origin_warehouse_table_headers: [
                    {key: 'seleccion', width: 80},
                    {key: 'product_name', label:'Producto'},
                    {key: 'quantity', label: 'Cantidad', width: 30}
                ],
                origin_warehouse_table_data: [],
                destination_warehouse_table_headers: [
                    {key: 'product_name', label: 'Producto'},
                    {key: 'quantity', label: 'Cantidad', width: 30}
                ],
                destination_warehouse_table_data: [],
                selected_products_headers: [
                    { key: 'product_name', label: 'Producto'},
                    { key: 'quantity', label: 'Existencias', width: 50},
                    { key: 'Cantidad', width: 50}, 
                    { key: 'seleccion', width: 20}
                ],
                historial_table_headers: [
                    {key: 'id_history_transfer', label: '#'},
                    {key: 'product_name', label: 'Producto'},
                    {key: 'quantity', label:'Cantidad'},
                    {key: 'vendor_name', label: 'Proveedor'},
                    {key: 'dc_name_origin', label: 'Origen'},
                    {key: 'dc_name_destination', label: 'Destino'},
                ],
                historial_table_data: [],
                selected_products_data: [],
                origin_warehouse_products: [],
                destination_warehouse_products: [],
                quantity: null,
                quantities: [],
                error_id: null,
                selected_origin_warehouse: null,
                selected_destination_warehouse: null,
                error_warehouse_id: null,
                error_selected_products: null,
                error: []
            };
        },
        methods: {
            cancel() {
                this.transaction_modal = false
                this.error = []
            },

            selectProducts(data) {
                
                if(this.selected_products_data.includes(data)) {
                    let index = this.selected_products_data.indexOf(data)
                    delete this.selected_products_data[index]
                    this.selected_products_data = this.selected_products_data.filter(item => true)
                    
                    
                }

                else {
                    this.selected_products_data.push(data)
                }
                
            },

            removeSelectedProduct(data) {

                let checkbox_index = this.origin_warehouse_table_data.indexOf(data.item)
                this.origin_warehouse_table_data[checkbox_index].checked = false

                let index = this.selected_products_data.indexOf(data.item)
                delete this.selected_products_data[index]
                this.selected_products_data = this.selected_products_data.filter( item => true)

                if(this.selected_products_data.length <= 0) {
                    this.transaction_modal = false
                }


            },

            checkWarehouseId() {
                this.error_warehouse_id = null
                this.error_selected_products = null

                if(_.isNull(this.destination_warehouse_id) || _.isEmpty(this.destination_warehouse_id)) {
                    this.error_warehouse_id = '*Selecciona un almacen'
                    return
                }

                if(_.isNull(this.origin_warehouse_id) || _.isEmpty(this.origin_warehouse_id)) {
                    this.error_selected_products = '*Selecciona un almacén'
                    return
                }

                else if(this.origin_warehouse_id == this.destination_warehouse_id) {
                    this.error_selected_products = '*El almacén de origen y destino es el mismo'
                    return
                }

                else if(this.selected_products_data.length <= 0) {
                    this.error_selected_products = '*Selecciona productos a mover'
                    return
                }

                let origin_warehouse_selected = this.warehouse_options.filter( option => option.id == this.origin_warehouse_id)

                this.selected_origin_warehouse = origin_warehouse_selected[0].label

                let destination_warehouse_selected = this.warehouse_options.filter( option => option.id == this.destination_warehouse_id)

                this.selected_destination_warehouse = destination_warehouse_selected[0].label

                this.transaction_modal = true
            },

            async checkOriginWarehouseProducts() {

                let payload = {
                    id_distribution_center: Number(this.origin_warehouse_id)
                }

                await this.$store.dispatch('product_transfers_module/listWarehouseProducts', payload)

                this.origin_warehouse_table_data = this.warehouse_products.map( product => {
                    return {
                        seleccion: '-',
                        id_distribution_center: product.id_distribution_center,
                        id_inventory_assing: product.id_inventory_assing,
                        product_name: product.product_name, 
                        quantity: product.quantity, 
                        value: null,
                        checked: false,
                        Cantidad: 'Cantidad:',
                    }
                })

                this.origin_warehouse_products = this.origin_warehouse_table_data
                
            },

            async checkDestinationWarehouseProducts () {
                
                let payload = {
                    id_distribution_center: Number(this.destination_warehouse_id)
                }
                
                await this.$store.dispatch('product_transfers_module/listWarehouseProducts', payload)
                
                this.destination_warehouse_table_data = this.warehouse_products.map( product => {
                    return {
                        id_distribution_center: product.id_distribution_center,
                        id_inventory_assing: product.id_inventory_assing,
                        product_name: product.product_name, 
                        quantity: product.quantity, 
                    }
                })

                this.destination_warehouse_products = this.destination_warehouse_table_data

            },

            async processTransaction(){

                this.error = []
                for(let selected_product in this.selected_products_data) {
                    if(this.selected_products_data[selected_product].value === null) {
                        this.error.push('*Cantidad')
                    } 

                    else if(this.selected_products_data[selected_product].value > this.selected_products_data[selected_product].quantity) {
                        this.error[selected_product] = '*Cantidad invalida'
                    }

                    else {
                        this.error.push(null)
                    }
                }

                if(!this.error.includes('*Cantidad') && !this.error.includes('*Cantidad invalida')) {

                    let products_assings = this.selected_products_data.map( product => {
                        return {
                            id_inventory_assing: product.id_inventory_assing,
                            id_product: product.id_product,
                            quantity: Number(product.value)
                        }
                    })

                    let payload = {
                        distribution_center: this.destination_warehouse_id,
                        assings: products_assings
                    }
                        
                    await this.$store.dispatch('product_transfers_module/betweenWarehouse', payload)
                    

                    await this.checkDestinationWarehouseProducts()
                    await this.checkOriginWarehouseProducts()
                    this.selected_products_data = []
                    this.transaction_modal = false
                }
   
            },

            async searchOriginItems() {
                if(!this.origin_warehouse_id) return

                if(!this.origin_search_query) {
                    await this.checkOriginWarehouseProducts()
                    return
                }

                this.origin_warehouse_table_data = this.origin_warehouse_table_data.filter( product => product.product_name.toLowerCase().includes(this.origin_search_query.toLowerCase()))
            },

            async searchDestinationItems() {
                if(!this.destination_warehouse_id) return

                if(!this.destination_search_query) {
                    await this.checkDestinationWarehouseProducts()
                    return
                }

                this.destination_warehouse_table_data = this.destination_warehouse_table_data.filter( product => product.product_name.toLowerCase().includes(this.destination_search_query.toLowerCase()))
            },

            getTransferHistory: async function () {

                await this.$store.dispatch('reports/listTransfersHistory', {
                    datatable: false,
                    date_init: this.dateParser(Date.now()),
                    date_end: this.dateParser(Date.now()),
                    type: 'between_dc'
                })

                this.historial_table_data = this.transfers;

                this.historial_modal = true;
            }

        },
        computed: {
            ...mapGetters({
                warehouses: 'warehouse/getWarehouses',
                inventory: 'product_transfers_module/getInventory',
                warehouse_products: 'product_transfers_module/getWarehouse',
                transfers: 'reports/getCollection'
            })
        },
        async mounted() {
            try {
                await this.$store.dispatch('warehouse/listWarehouses', {datatable: false})

                this.warehouse_options = this.warehouses.map( warehouse => {
                    return { id: warehouse.id_dc, label: warehouse.dc_name}
                })
                
            }
            catch (error) {
                console.log(error)
            }
        }
    };
</script>

<style lang="scss" scoped>


</style>