<template>
    <div id="reports" class="container-fluid">
        <titles title="Almacenes" :subtitle="tab_active" :othertitle="titletab_active"></titles>
        <tabs :array_tabs="tabs_array" @changeTab="controlTabs($event)"></tabs>
        <title-tabs :array_titletabs="titletabs_array" @changeTitle="controlTitles($event)"></title-tabs>

        <div v-if="tab_active == 'Productos'">
            <admin-products v-if="titletab_active == 'Administrador de almacenes'"></admin-products>
            <warehouse-transfers v-if="titletab_active == 'Traspasos entre almacenes'"></warehouse-transfers>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../components/Titles.vue'
    import Tabs from '../components/Tabs.vue'
    import TitleTabs from '../components/TitleTabs.vue' 

    //Icons
    import icon_movements from '../assets/icons/icon_movements.png'
    import icon_calendar from '../assets/icons/icon_calendar.png'

    //Views
    //Products
    import Adminproducts from "./Warehouses/Products/Adminproducts.vue";
    import WarehouseTransfers from "./Warehouses/Products/WarehouseToWarehouse.vue"

export default {
    name: 'Warehouses',
    components: {
        'titles': Titles,
        'tabs': Tabs,
        'title-tabs': TitleTabs,
        'admin-products': Adminproducts,
        'warehouse-transfers': WarehouseTransfers
    },
    data() {
        return {
            icon_calendar,
            tabs_array:[
                { img: icon_movements,  name: 'Productos', selected: true, key: 'products' }
            ],
            title_products: [
                { name: 'Administrador de almacenes', selected: true },
                { name: 'Traspasos entre almacenes', selected: false}
            ],
            titletabs_array:[],
            tab_active: null,
            titletab_active: null,
        };
    },
    methods: {
        async controlTabs(event) {
            this.tab_active = event
            for (let i in this.tabs_array) {
                if(this.tabs_array[i].selected){
                    this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                }
            }
            for (let i in this.titletabs_array) {
                if(this.titletabs_array[i].selected){
                    this.titletab_active = this.titletabs_array[i].name
                }
            }
        },
        async controlTitles(event) {
            this.titletab_active = event
            console.log(this.titletab_active);
        },
    },
    created(){
        for (let i in this.tabs_array) {
            if(this.tabs_array[i].selected){
                this.tab_active = this.tabs_array[i].name
                this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
            }
        }
        for (let i in this.titletabs_array) {
            if(this.titletabs_array[i].selected){
                this.titletab_active = this.titletabs_array[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>