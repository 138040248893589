<template>
    <div class="container-fluid bg-white p-2" style="border-radius: 15px">
        <div class="row">
            <div @click="changeTab(index)" :class="['col', 'd-flex', 'justify-content-center', 'align-items-center' , (item.selected) ? 'tab_selected' : 'tab_normal', 'py-2', 'mx-1']" style="border-radius:12px" v-for="(item, index) in array_tabs" :key="index">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center'">
                        <img :src="item.img" :class="[(item.selected) ? '' : 'filter-color']">
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center pt-2">
                        <span :class="[(item.selected) ? 'text-dark border_selected' : 'text-secondary', 'opensans-semibold' ]">{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ki-taps',
    props: {
        array_tabs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tab_selected: null
        };
    },
    mounted(){
        for (let i in this.array_tabs) {
            if(this.array_tabs[i].selected){
                this.tab_selected = this.array_tabs[i].name
            }
        }
    },
    methods: {
        changeTab(new_tab) {
            for (let i in this.array_tabs) {
                if(this.array_tabs[i].selected){
                    this.array_tabs[i].selected = false
                }
            }
            this.tab_selected = this.array_tabs[new_tab].name
            this.array_tabs[new_tab].selected = true
            this.$emit('changeTab', this.tab_selected);
        },
    },
};
</script>

<style lang="scss" scoped>
    .tab_selected{
        background: #f5eefa;
    }
    .border_selected{
        border-bottom:  2px solid #0d6ffd ;
    }
    .tab_normal:hover{
        background: #f0e7f8;
        color: black !important;
    }
</style>