<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box text-dark">
        
        <!-----Inputs-------->
        <div class="row bg-lightgray px-0 py-2 opensans-bold">
            <div class="col-6 px-0 pt-1">
                <div class="row px-0">

                    <div class="col-6 col-xxl-4 px-0 pl-2">
                        <ki-select label="Almacén" @change="checkWarehouseProducts"
                            :hidden_option="'Seleccione...'"
                            :options="warehouse_options"
                            v-model="warehouse_id"
                            full>
                        </ki-select>
                    </div>

                    <div class="col-6">
                        <ki-input @input="searchProducts" label="Busqueda:"
                            placeholder="Ej: playera"
                            v-model="search_query"
                            type="text"
                            full>

                        </ki-input>
                    </div>
                </div>
            </div>

        </div>

        <!-------Table-------->
        <div class="row px-0">
            <div class="col-12 opensans-bold">
                <p>
                    Productos en este almacén
                </p>
            </div>
            <div class="col-12" v-if="warehouse_table_data.length > 0">
                <ki-table :data="warehouse_table_data"
                    :allow_headers="warehouse_table_headers"
                    no_pagination color_body="white" color_headers="primary" 
                    color_headers_text="white" border_headers="primary">
                </ki-table>
            </div>

            <div class="col-12 text-center text-dark" v-if="warehouse_table_data.length <= 0 && this.warehouse_id">
                <p>
                    No encontraron productos en este almacen 
                </p>
            </div>
        </div>

        
    </div>
</template>

<script>


    //hooks
    import { mapGetters} from 'vuex'

    export default {
        name: 'ki-warehouse-products',
        data() {
            return {
              search_query: null,
              warehouse_options: [],
              warehouse_id: null,
              warehouse_table_headers: [
                { key: 'product_name', label: 'Producto'},
                { key: 'warehouse', label: 'Almacén'},
                { key: 'upc', label: 'Código'},
                { key: 'store_name', label: 'Distribuidor'},
                { key: 'quantity', label: 'Cantidad'}
              ],
              warehouse_table_data: []
            };
        },
        methods: {
            async checkWarehouseProducts() {

                let payload = {
                    id_distribution_center: Number(this.warehouse_id)
                }

                await this.$store.dispatch('product_transfers_module/listWarehouseProducts', payload)
                console.log(this.warehouse_products)
                this.warehouse_table_data = this.warehouse_products.map( product => {
                    return {
                        product_name: product.product_name,
                        upc: product.upc,
                        warehouse: product.dc_name,
                        store_name: product.store_name,
                        quantity: product.quantity
                    }
                })
            },
            searchProducts(){

                if(!this.warehouse_id) return

                if(!this.search_query) {
                    
                    this.warehouse_table_data = this.warehouse_products.map( product => {
                        return {
                            product_name: product.product_name,
                            warehouse: product.dc_name,
                            store_name: product.store_name,
                            quantity: product.quantity
                        }
                    })
                    return
                }

                else {
                    this.warehouse_table_data = this.warehouse_table_data.filter( item => 
                        item.product_name.toLowerCase().includes(this.search_query.toLowerCase()))
                    
                }
            }
        },
        computed: {
            ...mapGetters({
                warehouses: 'warehouse/getWarehouses',
                warehouse_products: 'product_transfers_module/getWarehouse'

            })
        },
        async mounted(){
            try {
                await this.$store.dispatch('warehouse/listWarehouses', {datatable: false})

                this.warehouse_options = this.warehouses.map( warehouse => {
                    return { id: warehouse.id_dc, label: warehouse.dc_name}
                })
  
        
            }
            catch (error) {
                //console.log(error)
               
            } 
        }
    };
</script>